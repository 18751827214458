import produce from 'immer'
import {STARTUP_ACTION} from "./StartupAction";
import {STARTUP_ACTION_STATUS} from "./_Startup";
import type {Startup, StartupSortType} from "./_Startup";

type StartupBranch = {
  entities: Array<Startup>,
  hasSubmittedEmail: boolean,
  industryTags: Array<string>,
  selectedIndustryTags: Array<string>
}

const defaultState: StartupBranch = {
  entities: [],
  industryTags: [],
  selectedIndustryTags: [],
  status: STARTUP_ACTION_STATUS.INACTIVE,
  error: ''
}

export default (state: StartupBranch = defaultState, action) => produce(state, (draft) => {
  switch (action.type) {
    case STARTUP_ACTION.STORE_STARTUPS:
      draft.entities = action.startups
      break
    case STARTUP_ACTION.SET_STATUS:
      draft.status = action.status
      break
    case STARTUP_ACTION.SET_ERROR:
      draft.error = action.error
      break
    case STARTUP_ACTION.STORE_INDUSTRY_TAGS:
      draft.industryTags = action.industryTags
      break
    case STARTUP_ACTION.STORE_MARKET_VERTICAL_KEYS:
      draft.marketVerticalSortKeys = action.marketVerticalKeys
      break
    case STARTUP_ACTION.SET_SELECTED_SECTOR_KEY:
      draft.selectedSectorSortKey = action.sectorKey
      break
    case STARTUP_ACTION.SET_SELECTED_MARKET_VERTICAL_KEY:
      draft.selectedMarketVerticalSortKey = action.marketVerticalKey
      break
    case STARTUP_ACTION.ADD_SELECTED_INDUSTRY_TAG:
      draft.selectedIndustryTags = [...draft.selectedIndustryTags, action.industryTag]
      break
    case STARTUP_ACTION.REMOVE_SELECTED_INDUSTRY_TAG:
      draft.selectedIndustryTags = draft.selectedIndustryTags.filter((tag) => tag !== action.industryTag)
      break
    case STARTUP_ACTION.SET_SELECTED_INDUSTRY_TAGS:
      draft.selectedIndustryTags = action.industryTags
      break
    default:
      break
  }
})
