export const APP_ACTION = {
  SET_SELECTED_GUIDE_SECTION: 'app/set-selected-guide-section',
  SET_HAS_SUBMITTED_EMAIL: 'app/set-has-submitted-email'
}

const AppAction = {
  setSelectedGuideSection: ({section}) => ({
    type: APP_ACTION.SET_SELECTED_GUIDE_SECTION,
    section: section
  }),
  setHasSubmittedEmail: ({hasSubmittedEmail}) => ({
    type: APP_ACTION.SET_HAS_SUBMITTED_EMAIL,
    hasSubmittedEmail: hasSubmittedEmail
  })
}

export default AppAction
