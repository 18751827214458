import { createStore, combineReducers, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import appReducer from "./app/appReducer";

import startupReducer from "./startup/startupReducer";
import startupSaga from "./startup/startupSaga";

import guideReducer from "./guide/guideReducer";
import guideSaga from "./guide/guideSaga";
import investorReducer from "./investor/investorReducer";
import investorSaga from "./investor/investorSaga";

const sagaMiddleware = createSagaMiddleware()

const reducers = combineReducers({
  app: appReducer,
  startup: startupReducer,
  guide: guideReducer,
  investor: investorReducer
})

const store = createStore(
  reducers,
  applyMiddleware(sagaMiddleware)
)

export { store }

sagaMiddleware.run(startupSaga)
sagaMiddleware.run(guideSaga)
sagaMiddleware.run(investorSaga)
