export const GUIDE_SECTION = {
  NEWS: 'news',
  ORGANIZATIONS: 'orgs',
  EVENTS: 'events',
  PLACES: 'places',
  PROGRAMS: 'programs',
  FUNDING: 'funding',
  EDUCATION: 'education'
}

export type GuideSection = GUIDE_SECTION.EDUCATION
  | GUIDE_SECTION.NEWS
  | GUIDE_SECTION.ORGANIZATIONS
  | GUIDE_SECTION.EVENTS
  | GUIDE_SECTION.PROGRAMS
  | GUIDE_SECTION.PLACES
  | GUIDE_SECTION.FUNDING
