import {select, call, put, delay, takeLatest} from 'redux-saga/effects'
import {gql} from 'apollo-boost'
import StartupServiceClient from "../../client/startup/startupClient";
import InvestorAction, {INVESTOR_ACTION} from "./InvestorAction";
import {INVESTOR_ACTION_STATUS} from "./_Investor";

export default function * investorSaga() {
  yield takeLatest(INVESTOR_ACTION.FETCH_INVESTORS, fetchInvestors)
  yield takeLatest(INVESTOR_ACTION.SUBMIT_INVESTOR, submitInvestor)
}

function * fetchInvestors() {
  try {
    const { data } = yield call(StartupServiceClient.query, {
      query: gql`
        query investors{
          investors {
            investors {
              entityId
              name
              contactFirstName
              contactLastName
              contactEmail
              websiteURL
              city
              state
              interestAreas
              financingTypes
              financingDescription
              status
            }
          }
        }
      `
    })

    let investorTags = []
    console.log("investors", data.investors.investors)
    for(let investor of data.investors.investors) {
      for(let tag of investor.interestAreas) {
        if((!investorTags.includes(tag) && tag?.length > 1)) {
          investorTags.push(tag)
        }
      }
      for(let tag of investor.financingTypes) {
        if((!investorTags.includes(tag) && tag?.length > 1)) {
          investorTags.push(tag)
        }
      }
    }
    console.log("tags", investorTags)

    yield put(InvestorAction.storeInvestorTags({investorTags: investorTags.sort((a,b) => {
        if(a.toLowerCase() < b.toLowerCase()) { return -1; }
        if(a.toLowerCase() > b.toLowerCase()) { return 1; }
        return 0;
      })}))

    yield put(InvestorAction.storeInvestors({investors: data.investors.investors.sort((a,b) => {
        if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        return 0;
      }).filter((investor) => investor.status === "APPROVED")}))

  } catch (err) {
    console.log('failed to fetch investors', err)
  }
}

function * submitInvestor({investor}) {
 try {
   console.log("investor", investor)
   let interestAreas = []
   for(let tag of investor.interestAreas){
     if(tag.customOption){
       interestAreas.push(tag.name)
     } else {
       interestAreas.push(tag)
     }
   }
   const { data } = yield call(StartupServiceClient.mutate, {
     mutation: gql`
         mutation mutateInvestor($name: String,
             $contactFirstName: String,
             $contactLastName: String,
             $contactEmail: String,
             $websiteURL: String,
             $city: String,
             $state: String,
             $interestAreas: [String]!,
             $financingTypes: [String],
             $financingDescription: String) {
             mutateInvestor(name: $name,
                 contactFirstName: $contactFirstName,
                 contactLastName: $contactLastName,
                 contactEmail: $contactEmail,
                 websiteURL: $websiteURL,
                 city: $city,
                 state: $state,
                 interestAreas: $interestAreas,
                 financingTypes: $financingTypes,
                 financingDescription: $financingDescription) {
                 entityId
             }
         }
     `,
     variables: {
       name: investor.name,
       contactFirstName: investor.contactFirstName,
       contactLastName: investor.contactLastName,
       contactEmail: investor.email,
       websiteURL: `https://${investor.websiteURL}`,
       city: investor.city,
       state: investor.state[0],
       interestAreas: interestAreas,
       financingTypes: investor.financingTypes,
       financingDescription: investor.description
     }
   })
   console.log("DATA", data)
   yield put(InvestorAction.setStatus({status: INVESTOR_ACTION_STATUS.SUBMISSION_COMPLETE}))
 } catch (err) {
   console.log('failed to submit investor', err)
 }
}
