import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

// import images
import startupKnoxLogo from '../../images/startupknox/logos/STK_logo_simple.png';
import Colors from "../../Colors";

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = { };
  }

  render() {
    return (
      <>
     <footer className="footer">
        <div className="container">
          <div className="row">
            <Row style={{display: 'flex', flex: 1, alignItems: 'center'}}>
              <div className="col-7">
                <Link className="logo-footer" to="/">
                  <img src={startupKnoxLogo} height={150} />
                </Link>
              </div>
              <div className="col-5 mb-0 mb-md-4 pb-0 pb-md-2">
                <div className="text-sm-right">
                  <p className="mb-0" style={{fontSize: 14 }}>
                    The Startup Knox Team is <a href="https://www.linkedin.com/in/brandonbruce/" target={"_blank"} rel='noopener noreferrer' style={{color: Colors.persimmon()}}>Brandon Bruce</a> and <a href="https://www.linkedin.com/in/johnbruck/" target={"_blank"} style={{color: Colors.persimmon()}}>John Bruck.</a><br/>
                    Design and layout by <a href="https://thethoughtbureau.com" target={"_blank"} rel='noopener noreferrer' style={{color: Colors.persimmon()}}>The Thought Bureau</a><br/>
                    Website by <a href="https://www.glacier.software/home" target={"_blank"} rel='noopener noreferrer' style={{color: Colors.persimmon()}}>Glacier Software</a><br/>

                    Special thanks to Adam Slack and Will Akers at <a href="https://tworoadsco.com/" target={"_blank"} rel='noopener noreferrer' style={{color: Colors.persimmon()}}>Two Roads</a> for the Startup Knox website domain.

                  </p>
                  <ul className="list-unstyled social-icon social text-sm-right mb-0">
                    <li className="list-inline-item mb-0"><a href="https://www.linkedin.com/company/startup-knox" target="_blank" rel='noopener noreferrer' className="rounded mr-1 text-white"><i className="mdi mdi-linkedin" title="Facebook"/></a></li>
                    <li className="list-inline-item mb-0"><a href="https://www.facebook.com/startupknox" target="_blank" rel='noopener noreferrer' className="rounded mr-1 text-white"><i className="mdi mdi-facebook" title="Facebook"/></a></li>
                    <li className="list-inline-item mb-0"><a href="http://www.instagram.com/startupknox " target="_blank" rel='noopener noreferrer' className="rounded mr-1 text-white"><i className="mdi mdi-instagram" title="Instagram"/></a></li>
                    <li className="list-inline-item mb-0"><a href="http://www.twitter.com/startup_knox" target="_blank" rel='noopener noreferrer' className="rounded mr-1 text-white"><i className="mdi mdi-twitter" title="Twitter"/></a></li>
                  </ul>
                </div>
              </div>
              <div className="col-12" style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <Link to="/contact-us" className="submitBnt btn btn-light">Contact Us</Link>
              </div>
            </Row>
          </div>
        </div>
        </footer>
        <hr />
        <footer className="footer footer-bar">
            <div className="container text-center">
                <div className="row align-items-center">
                    <div className="col-sm-12">
                        <div className="text-sm-left">
                          <p className="mb-0">©  {new Date().getFullYear()}  Startup Knox.<br/> <span style={{fontSize: 12}}>All Rights Reserved. Trademarks and resource descriptions are the property of their respective owners. You are free to download, print, and distribute as many non-commercial copies of the guide as you would like.</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

      </>
    );
  }
}

export default Footer;
