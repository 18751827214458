import produce from 'immer'
import {INVESTOR_ACTION} from "./InvestorAction";
import {INVESTOR_ACTION_STATUS} from "./_Investor";
import type {Investor} from "./_Investor";

type InvestorBranch = {
  entities: Array<Investor>,
  investorTags: Array<string>,
  selectedInvestorTags: Array<string>
}

const defaultState: InvestorBranch = {
  entities: [],
  investorTags: [],
  selectedInvestorTags: [],
  status: INVESTOR_ACTION_STATUS.INACTIVE,
  error: ''
}

export default (state: InvestorBranch = defaultState, action) => produce(state, (draft) => {
  switch (action.type) {
    case INVESTOR_ACTION.STORE_INVESTORS:
      draft.entities = action.investors
      break
    case INVESTOR_ACTION.SET_STATUS:
      draft.status = action.status
      break
    case INVESTOR_ACTION.SET_ERROR:
      draft.error = action.error
      break
    case INVESTOR_ACTION.STORE_INVESTOR_TAGS:
      draft.investorTags = action.investorTags
      break
    case INVESTOR_ACTION.SET_SELECTED_INVESTOR_TAGS:
      draft.selectedInvestorTags = action.investorTags
      break
    default:
      break
  }
})
