import React, {useEffect, useState} from 'react'
import {Row, Col, Alert} from 'reactstrap'
import {Spinner} from 'react-bootstrap'
import {API} from "aws-amplify";
import {motion} from "framer-motion";

export default function Modal_NewsLetter_SubScribe({}) {
  const variants = {
    visible: { opacity: 1, top: 150, transition: {ease: 'easeIn', duration: 0.4}, zIndex: 1000 },
    hidden: { opacity: 0, top: 0, transition: { ease: "easeOut", duration: 0.4 }, zIndex: -1 },
  }

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [isContactVisible, setIsContactVisible] = useState(false)
  const [variant, setVariant] = useState('hidden')

  useEffect(() => {
    setTimeout(() => {
      setVariant('visible')
    }, 5000)
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    let apiName = 'startup-knox';
    let path = 'submitEmail';
    let myInit = {
      body: {
        email: email,
        firstName: firstName,
        lastName: lastName,
        message: "New Startup Knox Subscriber!"
      },
      headers: {
        // 'Content-Type': 'application/json',
        // 'Accept': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Credentials': true
      } // OPTIONAL
    }

    API.post(apiName, path, myInit).then(response => {
      console.log('post succeeded!', response)
      setFirstName('')
      setLastName('')
      setEmail('')
      setIsContactVisible(true)

    }).catch(error => {
      console.log('post failed!', error)
      setFirstName('')
      setLastName('')
      setEmail('')
      setIsContactVisible(true)
    });
  }

  return (
    <motion.div
      animate={variant}
      variants={variants}
      style={{position: 'fixed', bottom: '0px', width: '100vw'}}
    >
      <div className="container">
        <Row className="justify-content-center">
          <Col lg={12}>
            <form onSubmit={handleSubmit} className="p-4 shadow bg-white rounded">
              <Alert color="info" isOpen={isContactVisible} toggle={()=>{ setIsContactVisible(!isContactVisible) }}>
                Thank you for joining!
              </Alert>
              <Row style={{justifyContent: 'flex-end'}}>
                <a
                  href={"#"}
                  id="hideModal"
                  onClick={(e) => {
                    e.preventDefault()
                    setVariant('hidden')
                  }}
                  style={{cursor: 'pointer'}}
                >
                  <span> X </span>
                </a>
              </Row>
              <Row>
                <Col lg={12} md={12}>
                  <h4 className="mb-3">Stay up to date with Startup Knox</h4>
                </Col>
              </Row>
              <Row className="text-left">
                <Col lg={3} md={3}>
                  <div className="form-group position-relative">
                    <label>First Name <span className="text-danger">*</span></label>
                    <i className="mdi mdi-account ml-3 icons"></i>
                    <input name="name" id="name" type="text" className="form-control pl-5" placeholder="First Name :" value={firstName} required onChange={(e) => setFirstName(e.target.value)}/>
                  </div>
                </Col>
                <Col lg={3} md={3}>
                  <div className="form-group position-relative">
                    <label>Last Name <span className="text-danger">*</span></label>
                    <i className="mdi mdi-account ml-3 icons"></i>
                    <input name="name" id="name" type="text" className="form-control pl-5" placeholder="Last Name :" value={lastName} required onChange={(e) => setLastName(e.target.value)}/>
                  </div>
                </Col>
                <Col lg={3} md={3}>
                  <div className="form-group position-relative">
                    <label>Your Email <span className="text-danger">*</span></label>
                    <i className="mdi mdi-email ml-3 icons"></i>
                    <input name="email" id="email" type="email" className="form-control pl-5" placeholder="Your email :" value={email} required onChange={(e) => setEmail(e.target.value)}/>
                  </div>
                </Col>

                <Col lg={3} md={3} style={{paddingTop: 30}}>
                  <Col sm={12} className="text-center">
                    <input type="submit" id="submit" name="send" className="submitBnt btn btn-primary btn-block" value="Join The Newsletter" />
                    <div id="simple-msg"></div>
                  </Col>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    </motion.div>
  )
}
