export const INVESTOR_ACTION = {
  FETCH_INVESTORS: 'investor/fetch-investors',
  SUBMIT_INVESTOR: 'investor/submit-investor',
  STORE_INVESTORS: 'investor/store-investors',
  SET_STATUS: 'investor/set-status',
  SET_ERROR: 'investor/set-error',
  STORE_INVESTOR_TAGS: 'investor/store-investor-tags',
  SET_SELECTED_INVESTOR_TAGS: 'investor/set-selected-investorTags'
}

const InvestorAction = {
  fetchInvestors: () => ({
    type: INVESTOR_ACTION.FETCH_INVESTORS
  }),
  storeInvestors: ({investors}) => ({
    type: INVESTOR_ACTION.STORE_INVESTORS,
    investors: investors
  }),
  submitInvestor: ({investor}) => ({
    type: INVESTOR_ACTION.SUBMIT_INVESTOR,
    investor: investor
  }),
  setStatus: ({status}) => ({
    type: INVESTOR_ACTION.SET_STATUS,
    status: status
  }),
  setError: ({error}) => ({
    type: INVESTOR_ACTION.SET_ERROR,
    error: error
  }),
  storeInvestorTags: ({investorTags}) => ({
    type: INVESTOR_ACTION.STORE_INVESTOR_TAGS,
    investorTags: investorTags
  }),
  setSelectedInvestorTags: ({investorTags}) => ({
    type: INVESTOR_ACTION.SET_SELECTED_INVESTOR_TAGS,
    investorTags: investorTags
  })
}

export default InvestorAction
