export const INVESTOR_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
}

export type InvestorStatus = INVESTOR_STATUS.ACCEPTED | INVESTOR_STATUS.PENDING_APPROVAL | INVESTOR_STATUS.REJECTED

export const INVESTOR_ACTION_STATUS = {
  INACTIVE: 'inactive',
  FETCHING: 'fetching',
  SUBMITTING_INVESTOR: 'submitting-investor',
  SUBMISSION_COMPLETE: 'submission-complete'
}

export type Investor = {
  entityId: string,
  name: string,
  contactFirstName: string,
  contactLastName: string,
  contactEmail: string,
  websiteURL: string,
  city: string,
  state: string,
  interestAreas: Array<string>,
  financingTypes: Array<string>,
  financingDescription: string,
  status: InvestorStatus
}
