export const GUIDE_ACTION = {
  FETCH_GUIDE_ENTITIES: 'guide/fetch-guide-entities',
  SUBMIT_GUIDE_ENTITY: 'guide/submit-guide-entity',
  STORE_GUIDE_ENTITIES: 'guide/store-guide-entities',
  SET_STATUS: 'guide/set-status',
  SET_ERROR: 'guide/set-error',
  SET_SELECTED_SORT_KEY_TYPE: 'guide/set-selected-sort-key-type',
  SET_SELECTED_SECTOR_KEY: 'guide/set-selected-sector-key',
  SET_SELECTED_MARKET_VERTICAL_KEY: 'guide/set-selected-market-vertical-key',
  STORE_MARKET_VERTICAL_KEYS: 'guide/store-market-vertical-keys',
  STORE_GUIDE_TAGS: 'guide/store-guide-tags',
  ADD_SELECTED_INDUSTRY_TAG: 'guide/add-selected-industry-tag',
  REMOVE_SELECTED_INDUSTRY_TAG: 'guide/remove-selected-industryTag',
  SET_SELECTED_GUIDE_TAGS: 'guide/set-selected-guideTags'
}

const GuideAction = {
  fetchGuideEntities: () => ({
    type: GUIDE_ACTION.FETCH_GUIDE_ENTITIES
  }),
  storeGuideEntities: ({entities}) => ({
    type: GUIDE_ACTION.STORE_GUIDE_ENTITIES,
    entities: entities
  }),
  submitGuideEntity: ({entity}) => ({
    type: GUIDE_ACTION.SUBMIT_GUIDE_ENTITY,
    entity: entity
  }),
  setStatus: ({status}) => ({
    type: GUIDE_ACTION.SET_STATUS,
    status: status
  }),
  setError: ({error}) => ({
    type: GUIDE_ACTION.SET_ERROR,
    error: error
  }),
  storeGuideTags: ({guideTags}) => ({
    type: GUIDE_ACTION.STORE_GUIDE_TAGS,
    guideTags: guideTags
  }),
  setSelectedGuideTags: ({guideTags}) => ({
    type: GUIDE_ACTION.SET_SELECTED_GUIDE_TAGS,
    guideTags: guideTags
  })
}

export default GuideAction
