export default {
  amplify: {
    endpoints: [
      {
        name: "startup-knox",
        endpoint: "https://is3jijdx64.execute-api.us-east-1.amazonaws.com/prod/"
      }
    ]
  }
}
