import React from 'react';
import { Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';
import Layout from './components/Layout/';
import { Provider } from 'react-redux'
import {store} from "./state/store";
import Amplify from 'aws-amplify';
import config from './config'

// Import Css
import './Apps.scss';
import './css/materialdesignicons.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import routes from './routes';
import Modal_NewsLetter_SubScribe from "./components/Modal/Modal_Newsletter_Subscribe";
const PageMaintenance = React.lazy(() => import('./pages/PageMaintenance'));

function withLayout(WrappedComponent) {
  return class extends React.Component {
    render() {
      return <Layout>
        <WrappedComponent/>
      </Layout>
    }
  };
}

Amplify.configure(config.amplify)

function App() {
  return (
    <React.Fragment>
      <Router>
        <Provider store={store}>
          <React.Suspense fallback={<div/>}>
            <Modal_NewsLetter_SubScribe/>
            <Switch>
              <Route path="/page-maintenance" component={PageMaintenance} />
              {routes.map((route, idx) =>
                <Route path={route.path} component={withLayout(route.component)} key={idx} />
              )}
            </Switch>
          </React.Suspense>
        </Provider>
      </Router>
    </React.Fragment>
  );
}

export default withRouter(App);
