import {ApolloClient, InMemoryCache} from 'apollo-boost/lib/index'
import {createHttpLink} from 'apollo-link-http'
import 'cross-fetch/polyfill'
import config from '../../build/config'
//const fetch = require('node-fetch')

const cache = new InMemoryCache()

const StartupServiceClient = new ApolloClient({
  cache: cache,
  link: createHttpLink({
    uri: config.apis.startupService.host,
    //fetch: fetch
  }),
  // fetchOptions: {
  //   mode: 'no-cors',
  // }
})

StartupServiceClient.defaultOptions.query = {
  fetchPolicy: 'no-cache'
}

export default StartupServiceClient



