import React from 'react';
const Guide = React.lazy(() =>  import('./pages/Guide/index'))
const Home = React.lazy(() => import('./pages/Home/index'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'))
const Startups = React.lazy(() => import('./pages/Startups/index'))
const Investors = React.lazy(() => import('./pages/Investors/index'))
const Podcast = React.lazy(() => import('./pages/Podcast/podcast'))
const routes = [
  { path: '/guide', component: Guide },
  { path: '/contact-us', component: ContactUs},
  { path: '/startups', component: Startups},
  { path: '/investors', component: Investors},
  { path: '/podcast', component: Podcast},
  { path: '/', component: Home }
];

export default routes
