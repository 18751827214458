import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {withRouter} from "react-router"
import startupKnoxLogo from '../../images/startupknox/logos/STK_logo_simple.png';
import Colors from "../../Colors";
import {connect} from "react-redux";
import AppAction from "../../state/app/AppAction";
import {GUIDE_SECTION} from "../../state/app/_App";

class Topbar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      page: false,
      landing: false,
      docs: false,
      new: false,
      utility: false,
      user: false,
      work: false,
      blog: false,
      carr: false
    }
    this.toggleLine = this.toggleLine.bind(this)
  }

  toggleLine() {
    this.setState(prevState => ({isOpen: !prevState.isOpen}))
  }

  componentDidMount() {
    var matchingMenuItem = null
    var ul = document.getElementById("top-menu")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem)
    }
  }

  activateParentDropdown = (item) => {
    const parent = item.parentElement
    if (parent) {
      parent.classList.add('active') // li
      const parent1 = parent.parentElement
      parent1.classList.add('active') // li
      if (parent1) {
        const parent2 = parent1.parentElement
        parent2.classList.add('active') // li
        if (parent2) {
          const parent3 = parent2.parentElement
          parent3.classList.add('active') // li
          if (parent3) {
            const parent4 = parent3.parentElement
            parent4.classList.add('active') // li
          }
        }
      }
    }
  }

  render() {
    return (
      <>
        <header id="topnav" className="defaultscroll sticky">
          <div className="container" style={{alignItems: 'center', justifyContent: 'space-between', display: 'flex', flex: 1}}>
            <div>
              <Link to="/index">
                  {/*<img src={startupKnoxLogo} height={70} />*/}
                  <p className='display-4' style={{color: Colors.darkBlue(), marginBottom: 0}}>Startup Knox</p>
              </Link>
            </div>
            <div className="menu-extras">
              <div className="menu-item">
                <Link to="#" onClick={this.toggleLine}
                      className={this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"}>
                  <div className="lines">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Link>
              </div>
            </div>

            <div id="navigation" style={{display: this.state.isOpen ? "block" : "none"}}>
              <ul className="navigation-menu" id="top-menu">
                <li style={{color: Colors.darkBlue()}}><Link to="/">Home</Link></li>
                <li className="has-submenu">
                  <Link to="/guide" onClick={(event) => {
                    event.preventDefault()
                    this.setState({landing: !this.state.landing})
                  }} style={{color: Colors.darkBlue()}} >The Guide</Link>
                  <span className="menu-arrow"></span>
                  <ul className={this.state.landing ? "submenu megamenu open" : "submenu megamenu"}>
                    <li>
                      <ul>
                        <li style={{color: Colors.darkBlue()}}><Link to="/guide" onClick={() => {
                          this.props.setSelectedGuideSection({section: GUIDE_SECTION.NEWS})
                        }}>News</Link></li>
                        <li style={{color: Colors.darkBlue()}}><Link to="/guide" onClick={() => {
                          this.props.setSelectedGuideSection({section: GUIDE_SECTION.EVENTS})

                        }}>Events</Link></li>
                        <li style={{color: Colors.darkBlue()}}><Link to="/guide" onClick={() => {
                          this.props.setSelectedGuideSection({section: GUIDE_SECTION.ORGANIZATIONS})

                        }}>Organizations</Link></li>
                        <li style={{color: Colors.darkBlue()}}><Link to="/guide" onClick={() => {
                          this.props.setSelectedGuideSection({section: GUIDE_SECTION.PROGRAMS})

                        }}>Programs</Link></li>
                        <li style={{color: Colors.darkBlue()}}><Link to="/guide" onClick={() => {
                          this.props.setSelectedGuideSection({section: GUIDE_SECTION.PLACES})

                        }}>Places</Link></li>
                        <li style={{color: Colors.darkBlue()}}><Link to="/guide" onClick={() => {
                          this.props.setSelectedGuideSection({section: GUIDE_SECTION.FUNDING})

                        }}>Funding</Link></li>
                        <li style={{color: Colors.darkBlue()}}><Link to="/guide" onClick={() => {
                          this.props.setSelectedGuideSection({section: GUIDE_SECTION.EDUCATION})

                        }}>Education</Link></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li style={{color: Colors.darkBlue()}}><Link to="/startups">Startups</Link></li>
                <li style={{color: Colors.darkBlue()}}><Link to="/investors">Investors</Link></li>

                <li><a href={'https://www.eventbrite.com/c/knoxville-events-for-startups-cwwtwqy--2cchshC1XNzM/'} target="_blank" rel='noopener noreferrer' style={{flexWrap: 'wrap'}}><span className={'text'} style={{color: Colors.darkBlue()}}>Events</span></a></li>
                <li style={{color: Colors.darkBlue()}}><Link to="/podcast">Podcast</Link></li>

              </ul>
            </div>
          </div>
        </header>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
  setSelectedGuideSection: ({section}) => dispatch(AppAction.setSelectedGuideSection({section: section}))
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Topbar))
