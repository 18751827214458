export const STARTUP_ACTION = {
  FETCH_STARTUPS: 'startup/fetch-startups',
  SUBMIT_STARTUP: 'startup/submit-startup',
  STORE_STARTUPS: 'startup/store-startups',
  SET_STATUS: 'startup/set-status',
  SET_ERROR: 'startup/set-error',
  SET_SELECTED_SORT_KEY_TYPE: 'startup/set-selected-sort-key-type',
  SET_SELECTED_SECTOR_KEY: 'startup/set-selected-sector-key',
  SET_SELECTED_MARKET_VERTICAL_KEY: 'startup/set-selected-market-vertical-key',
  STORE_MARKET_VERTICAL_KEYS: 'startup/store-market-vertical-keys',
  STORE_INDUSTRY_TAGS: 'startup/store-industry-tags',
  ADD_SELECTED_INDUSTRY_TAG: 'startup/add-selected-industry-tag',
  REMOVE_SELECTED_INDUSTRY_TAG: 'startup/remove-selected-industryTag',
  SET_SELECTED_INDUSTRY_TAGS: 'startup/set-selected-industryTags'
}

const StartupAction = {
  fetchStartups: () => ({
    type: STARTUP_ACTION.FETCH_STARTUPS
  }),
  storeStartups: ({startups}) => ({
    type: STARTUP_ACTION.STORE_STARTUPS,
    startups: startups
  }),
  submitStartup: ({startup}) => ({
    type: STARTUP_ACTION.SUBMIT_STARTUP,
    startup: startup
  }),
  setStatus: ({status}) => ({
    type: STARTUP_ACTION.SET_STATUS,
    status: status
  }),
  setError: ({error}) => ({
    type: STARTUP_ACTION.SET_ERROR,
    error: error
  }),
  setSelectedSortKeyType: ({sortKeyType}) => ({
    type: STARTUP_ACTION.SET_SELECTED_SORT_KEY_TYPE,
    sortKeyType: sortKeyType
  }),
  setSelectedSectorKey: ({sectorKey}) => ({
    type: STARTUP_ACTION.SET_SELECTED_SECTOR_KEY,
    sectorKey: sectorKey
  }),
  setSelectedMarketVerticalKey: ({marketVerticalKey}) => ({
    type: STARTUP_ACTION.SET_SELECTED_MARKET_VERTICAL_KEY,
    marketVerticalKey: marketVerticalKey
  }),
  storeMarketVerticalKeys: ({marketVerticalKeys}) => ({
    type: STARTUP_ACTION.STORE_MARKET_VERTICAL_KEYS,
    marketVerticalKeys: marketVerticalKeys
  }),
  storeIndustryTags: ({industryTags}) => ({
    type: STARTUP_ACTION.STORE_INDUSTRY_TAGS,
    industryTags: industryTags
  }),
  addSelectedIndustryTag: ({industryTag}) => ({
    type: STARTUP_ACTION.ADD_SELECTED_INDUSTRY_TAG,
    industryTag: industryTag
  }),
  removeSelectedIndustryTag: ({industryTag}) =>({
    type: STARTUP_ACTION.REMOVE_SELECTED_INDUSTRY_TAG,
    industryTag: industryTag
  }),
  setSelectedIndustryTags: ({industryTags}) => ({
    type: STARTUP_ACTION.SET_SELECTED_INDUSTRY_TAGS,
    industryTags: industryTags
  })
}

export default StartupAction
