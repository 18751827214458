export const STARTUP_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
}

export type StartupStatus = STARTUP_STATUS.ACCEPTED | STARTUP_STATUS.PENDING_APPROVAL | STARTUP_STATUS.REJECTED

export const STARTUP_ACTION_STATUS = {
  INACTIVE: 'inactive',
  FETCHING: 'fetching',
  SUBMITTING_STARTUP: 'submitting-startup',
  SUBMISSION_COMPLETE: 'submission-complete'
}

export type Startup = {
  entityId: string,
  entityName: string,
  websiteURL: string,
  memberFirstName: string,
  memberLastName: string,
  createdAt: string,
  marketVertical: string,
  memberEmail: string,
  description: string,
  sector: string,
  status: StartupStatus
}
export const STARTUP_SORT_TYPES = {
  MARKET_VERTICAL: 'Market Vertical',
  SECTOR: 'Sector'
}
export type StartupSortType = STARTUP_SORT_TYPES.MARKET_VERTICAL | STARTUP_SORT_TYPES.SECTOR
