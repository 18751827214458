import {select, call, put, delay, takeLatest} from 'redux-saga/effects'
import {gql} from 'apollo-boost'
import StartupServiceClient from "../../client/startup/startupClient";
import {GUIDE_ACTION_STATUS} from "./_Guide";
import GuideAction, {GUIDE_ACTION} from "./GuideAction";

export default function * startupSaga() {
  yield takeLatest(GUIDE_ACTION.FETCH_GUIDE_ENTITIES, fetchGuideEntities)
  yield takeLatest(GUIDE_ACTION.SUBMIT_GUIDE_ENTITY, submitGuideEntity)
}

function * fetchGuideEntities() {
  try {
    const { data } = yield call(StartupServiceClient.query, {
      query: gql`
        query entities{
          guideEntities {
            guideEntities {
                entityId
                type
                websiteURL
                title
                tags
                description
                status
                priorityLevel
            }
          }
        }
      `
    })

    let guideTags = []
    for(let entity of data.guideEntities.guideEntities) {
      for(let tag of entity.tags) {
        if((!guideTags.includes(tag) && tag?.length > 1)) {
          guideTags.push(tag)
        }
      }
    }

    yield put(GuideAction.storeGuideTags({guideTags: guideTags.sort((a,b) => {
        if(a.toLowerCase() < b.toLowerCase()) { return -1; }
        if(a.toLowerCase() > b.toLowerCase()) { return 1; }
        return 0;
      })}))

    yield put(GuideAction.storeGuideEntities({entities: data.guideEntities.guideEntities.sort((a,b) => {
        if(a.priorityLevel > b.priorityLevel) { return -1; }
        if(a.priorityLevel < b.priorityLevel) { return 1; }
        return 0;
      }).filter((entity) => entity.status === "APPROVED")}))

  } catch (err) {
    console.log('failed to fetch guide', err)
  }
}

function * submitGuideEntity({entity}) {
 try {
   console.log("Entity", entity)
   let mappedTags = []

   for(let tag of entity.tags){
       mappedTags.push(tag)
   }
   mappedTags.push(entity.type[0])
   console.log("tags", mappedTags, entity.type[0].toUpperCase())
   const { data } = yield call(StartupServiceClient.mutate, {
     mutation: gql`
         mutation mutateGuideEntity($type: EntityType!,$websiteURL: String,$title: String!,$tags: [String]!,$description: String!,$priorityLevel: Int) {
             mutateGuideEntity(type: $type, websiteURL: $websiteURL, title: $title, tags: $tags, description: $description, priorityLevel: $priorityLevel) {
                 entityId
             }
         }
     `,
     variables: {
       type: entity.type[0].toUpperCase(),
       websiteURL: `https://${entity.websiteURL}`,
       title: entity.title,
       tags: mappedTags,
       description: entity.description,
       priorityLevel: 0
     }
   })
   console.log("DATA", data)
   yield put(GuideAction.setStatus({status: GUIDE_ACTION_STATUS.SUBMISSION_COMPLETE}))
 } catch (err) {
   console.log('failed to submit guide', err)
 }
}
