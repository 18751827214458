import {select, call, put, delay, takeLatest} from 'redux-saga/effects'
import {gql} from 'apollo-boost'
import StartupAction, {STARTUP_ACTION} from "./StartupAction";
import StartupServiceClient from "../../client/startup/startupClient";
import {STARTUP_ACTION_STATUS} from "./_Startup";

export default function * startupSaga() {
  yield takeLatest(STARTUP_ACTION.FETCH_STARTUPS, fetchStartups)
  yield takeLatest(STARTUP_ACTION.SUBMIT_STARTUP, submitStartup)
}

function * fetchStartups() {
  try {
    const { data } = yield call(StartupServiceClient.query, {
      query: gql`
          query startups{
              startups {
                  startups {
                      entityId
                      entityName
                      memberFirstName
                      memberLastName
                      industryTags
                      description
                      websiteURL
                      status
                  }
              }
          }
      `
    })

    let industryTags = []
    for(let startup of data.startups.startups) {
      for(let tag of startup.industryTags) {
        if((!industryTags.includes(tag) && tag?.length > 1)) {
          industryTags.push(tag)
        }
      }
    }

    yield put(StartupAction.storeIndustryTags({industryTags: industryTags.sort((a,b) => {
        if(a.toLowerCase() < b.toLowerCase()) { return -1; }
        if(a.toLowerCase() > b.toLowerCase()) { return 1; }
        return 0;
      })}))

    yield put(StartupAction.storeStartups({startups: data.startups.startups.sort((a,b) => {
        if(a.entityName.toLowerCase() < b.entityName.toLowerCase()) { return -1; }
        if(a.entityName.toLowerCase() > b.entityName.toLowerCase()) { return 1; }
        return 0;
      }).filter((startup) => startup.status === "APPROVED")}))

  } catch (err) {
    console.log('failed to fetch startups', err)
  }
}

function * submitStartup({startup}) {
 try {
   console.log("STARTUP", startup)
   let mappedTags = []

   for(let tag of startup.industryTags){
     if(tag.customOption){
       mappedTags.push(tag.name)
     } else {
       mappedTags.push(tag)
     }
   }
   const { data } = yield call(StartupServiceClient.mutate, {
     mutation: gql`
         mutation mutateStartup($memberEmail: String!, $entityName: String!, $websiteURL: String, $memberFirstName: String!, $memberLastName: String!,$description: String!, $industryTags: [String]){
             mutateStartup(memberEmail: $memberEmail, entityName: $entityName, websiteURL: $websiteURL, memberFirstName: $memberFirstName, memberLastName: $memberLastName, description: $description, industryTags: $industryTags) {
                 entityId
             }
         }
     `,
     variables: {
       entityName: startup.startupName,
       websiteURL: startup.websiteURL.length > 1? `https://${startup.websiteURL}` : undefined,
       memberFirstName: startup.memberFirstName,
       memberLastName: startup.memberLastName,
       description: startup.description.length > 1? startup.description : "UNINITIALIZED",
       memberEmail: startup.email,
       industryTags: mappedTags
     }
   })
   console.log("DATA", data)
   yield put(StartupAction.setStatus({status: STARTUP_ACTION_STATUS.SUBMISSION_COMPLETE}))
 } catch (err) {
   console.log('failed to submite startup', err)
 }
}
