export const GUIDE_ENTITY_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED'
}

export type GuideStatus = GUIDE_ENTITY_STATUS.ACCEPTED | GUIDE_ENTITY_STATUS.PENDING_APPROVAL | GUIDE_ENTITY_STATUS.REJECTED

export const GUIDE_ACTION_STATUS = {
  INACTIVE: 'inactive',
  FETCHING: 'fetching',
  SUBMITTING_GUIDE_ENTITY: 'submitting-guide-entity',
  SUBMISSION_COMPLETE: 'submission-complete'
}

export type GuideActionStatus = GUIDE_ACTION_STATUS.INACTIVE | GUIDE_ACTION_STATUS.FETCHING | GUIDE_ACTION_STATUS.SUBMITTING_GUIDE_ENTITY | GUIDE_ACTION_STATUS.SUBMISSION_COMPLETE

export type GuideEntity = {
  entityId: string,
  type: string,
  websiteURL: string,
  title: string,
  tags: Array<string>,
  description: string,
  status: GuideStatus
}
