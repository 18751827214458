import produce from 'immer'
import type {GuideSection} from "./_App";
import {APP_ACTION} from "./AppAction";
import {GUIDE_SECTION} from "./_App";

type AppBranch = {
  selectedGuideSection: GuideSection,
  hasSubmittedEmail: boolean
}

const defaultState: AppBranch = {
  selectedGuideSection: GUIDE_SECTION.NEWS,
  hasSubmittedEmail: false
}

export default (state: AppBranch = defaultState, action) => produce(state, (draft) => {
  switch (action.type) {
    case APP_ACTION.SET_SELECTED_GUIDE_SECTION:
      console.log("!!!", action)
      draft.selectedGuideSection = action.section
      break
    case APP_ACTION.SET_HAS_SUBMITTED_EMAIL:
      draft.hasSubmittedEmail = action.hasSubmittedEmail
  }
})
