import produce from 'immer'
import type {GuideActionStatus, GuideEntity} from "./_Guide";
import {GUIDE_ACTION_STATUS} from "./_Guide";
import {GUIDE_ACTION} from "./GuideAction";

type StartupBranch = {
  entities: Array<GuideEntity>,
  guideTags: Array<string>,
  selectedGuideTags: Array<string>,
  status: GuideActionStatus
}

const defaultState: StartupBranch = {
  entities: [],
  guideTags: [],
  selectedGuideTags: [],
  status: GUIDE_ACTION_STATUS.INACTIVE,
  error: ''
}

export default (state: StartupBranch = defaultState, action) => produce(state, (draft) => {
  switch (action.type) {
    case GUIDE_ACTION.STORE_GUIDE_ENTITIES:
      draft.entities = action.entities
      break
    case GUIDE_ACTION.SET_STATUS:
      draft.status = action.status
      break
    case GUIDE_ACTION.SET_ERROR:
      draft.error = action.error
      break
    case GUIDE_ACTION.STORE_GUIDE_TAGS:
      draft.guideTags = action.guideTags
      break
    case GUIDE_ACTION.SET_SELECTED_GUIDE_TAGS:
      draft.selectedGuideTags = action.guideTags
      break
    default:
      break
  }
})
