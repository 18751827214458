const Colors = {
  darkBlue: (opacity = 1) => `rgba(03,50,76,${opacity})`,
  lightBlue: (opacity = 1) => `rgba(158,207,216,${opacity})`,
  persimmon: (opacity = 1) => `rgba(243,112,81,${opacity})`,
  green: (opacity = 1) => `rgba(181,188,89,${opacity})`,
  pale: (opacity = 1) => `rgba(249,229,219,${opacity})`,
  grey: (opacity = 1) => `rgba(102,103,102,${opacity})`,
  white: (opacity = 1) => `rgba(255,255,255,${opacity})`
}
export default Colors
//background: rgb(243,112,81);
//background: linear-gradient(347deg, rgba(243,112,81,1) 0%, rgba(243,112,81,1) 7%, rgba(249,229,219,1) 100%);
